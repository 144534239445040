<template>
    <div>
        <v-dialog v-model="visible" transition="dialog-top-transition" scrollable persistent max-width="700">
            <v-card class="modal-maincard">

                <v-toolbar dark flat dense>
                    <v-toolbar-title>{{this.title}}</v-toolbar-title>
                </v-toolbar>

                <v-card-text class="wrapperFormModal" v-if="reportOpenData">

                    <v-row v-if="reportOpenData.param == 'year-param'" no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label">{{$t("Год")}}</label>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">
                            <v-select
                                return-object
                                :items="years"
                                v-model="yearModel"
                                hide-details
                                required
                                outlined
                                dense
                                :menu-props="{ bottom: true, offsetY: true }"
                                @click:clear="yearModel = null"
                                append-icon="fas fa-chevron-down"
                            ></v-select>
                        </v-col>
                    </v-row>   

                    <v-row v-if="reportOpenData.param == 'period-param'" no-gutters>

                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label">{{ $t("Период") }}</label>
                        </v-col>
                        
                        <v-col cols="12" sm="12" md="8">
                            <div class="more-inputs-in-row">
                                <v-menu 
                                    v-model="ReportDateMenuStart"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            :value="ReportDateStartModel | formattedDate"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on" 
                                            hide-details
                                            required
                                            outlined
                                            dense
                                            @click:clear="ReportDateStartModel = null"
                                            class="datepick-input"
                                        >
                                        </v-text-field>
                                    </template>

                                    <v-date-picker 
                                        v-model="ReportDateStartModel"
                                        @input="ReportDateMenuStart = false"
                                        color="teal"
                                        :max="ReportDateEndModel"
                                        :first-day-of-week="1"
                                    >
                                    </v-date-picker>
                                </v-menu>
                                <span>{{$t("до")}}</span>
                                <v-menu 
                                    v-model="ReportDateMenuEnd"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            :value="ReportDateEndModel | formattedDate"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on" 
                                            hide-details
                                            required
                                            outlined
                                            dense
                                            @click:clear="ReportDateEndModel = null"
                                            class="datepick-input"
                                        >
                                        </v-text-field>
                                    </template>

                                    <v-date-picker 
                                        v-model="ReportDateEndModel"
                                        @input="ReportDateMenuEnd = false"
                                        color="teal"
                                        :min="ReportDateStartModel"
                                        :first-day-of-week="1"
                                    >
                                    </v-date-picker>
                                </v-menu>
                            </div>
                        </v-col>

                    </v-row>

                    <v-row
                        v-if="reportOpenData.param == 'period-param' && reportOpenData.type == 'Documents.C2.Euol_1_OL'"
                        no-gutters
                    >

                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label">{{ $t("Категория_лица") }}</label>
                        </v-col>
                        
                        <v-col cols="12" sm="12" md="8">
                            <v-select
                                v-model="euolDeclarantCategory"
                                :items="euolDeclarantCategories"
                                :item-text="item => $refLocale(item, $i18n.locale)"
                                item-value="id"
                                hide-details
                                required
                                outlined
                                dense
                                append-icon="fas fa-chevron-down"
                                :menu-props="{ bottom: true, offsetY: true }"
                            >
                            </v-select>
                        </v-col>

                    </v-row>

                    <v-row
                        v-if="false"
                        no-gutters
                    >

                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label">{{ $t("Язык_отчета") }}</label>
                        </v-col>
                        
                        <v-col cols="12" sm="12" md="8">
                            <v-select
                                v-model="language"
                                :items="languages"
                                :item-text="item => $refLocale(item, $i18n.locale)"
                                item-value="id"
                                hide-details
                                required
                                outlined
                                dense
                                append-icon="fas fa-chevron-down"
                                :menu-props="{ bottom: true, offsetY: true }"
                            >
                            </v-select>
                        </v-col>

                    </v-row>

                    <v-row v-if="reportOpenData.param == 'date-param'"  no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label">{{$t("Дата")}}</label>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">
                            <v-menu 
                                v-model="ReportDateMenu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        :value="ReportDateModel | formattedDate"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on" 
                                        hide-details
                                        required
                                        outlined
                                        dense
                                        @click:clear="ReportDateModel = null"
                                        class="datepick-input"
                                    >
                                    </v-text-field>
                                </template>

                                <v-date-picker 
                                    v-model="ReportDateModel"
                                    @input="ReportDateMenu = false"
                                    color="teal"
                                    :first-day-of-week="1"
                                >
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>   
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn 
                        color="cyan" 
                        text
                        depressed 
                        @click="generate_report_excel" 
                        v-if="ReportDateModel || yearModel || (ReportDateStartModel && ReportDateEndModel)"
                    >
                        {{$t("сформировать_отчет_excel")}}
                    </v-btn>

                    <v-btn 
                        color="cyan" 
                        text
                        depressed 
                        @click="generate_report_pdf" 
                        v-if="reportOpenData && reportOpenData.type != 'Documents.C2.CGO_MIO' && reportOpenData.type != 'Documents.C2.TotalVolumeSED' && ( ReportDateModel || yearModel || (ReportDateStartModel && ReportDateEndModel) )"
                    >
                        {{$t("сформировать_отчет_pdf")}}
                    </v-btn>

                    <v-btn 
                        color="blue-grey" 
                        text
                        depressed 
                        @click="cancel"
                    >
                        {{$t("Отмена")}}
                    </v-btn>

                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import i18n from '@/i18n'
import sys from '@/services/system';

export default {
    name: "ReportPeriodDlg",
    props:
    {
        value:
        {
            type: Object,
            default: null
        }
    },
    data () {
        return {
            title: i18n.t("Параметры_отчета"),
            visible: false,
            resolve: null,
            reject: null,
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },
            reportOpenData: null,
            selectedPeriod: null,
            years: [...Array(20)].map((a,b)=> new Date().getFullYear() - b),
            yearModel: null,
            val: JSON.parse(JSON.stringify(this.value)),
            ReportDateMenu: false,
            ReportDateModel: null,
            ReportDateMenuStart: false,
            ReportDateStartModel: null,
            ReportDateMenuEnd: false,
            ReportDateEndModel: null,
            language: 'ru-RU',
            languages: [
                { id: 'kk-KZ', Value: this.$t("казахский") },
                { id: 'ru-RU', Value: this.$t("русский") }
            ],
            euolDeclarantCategory: 1,
            euolDeclarantCategories: [
                { id: 1, Value: this.$t("Физические_лица") },
                { id: 2, Value: this.$t("Юридические_лица") }
            ]
        }
    },
    computed: {
       
    },
    filters: {
        formattedDate: function (value) {
            var formattedDate = sys.dateFormat(value, 'DD.MM.YYYY');
            return formattedDate ?? "";
        }
    },
    methods: {
        async open(reportItem, options = {})
        {
            this.reset();

            this.reportOpenData = reportItem;
            this.visible = true;
            this.search = null,
            this.options = Object.assign(this.options, options);

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        cancel() {
            this.visible = false;
            this.reject({
                isCancelled: true,
                message: "Действие_отменено"
            });
        },
        generate_report_excel() {
            this.visible = false;
            this.resolve({ type: 'Xls', param: this.getResultObject() });
        },
        generate_report_pdf() {
            this.visible = false;
            this.resolve({ type: 'PDF', param: this.getResultObject() });
        },
        getResultObject() {
            if (this.reportOpenData){
                switch (this.reportOpenData.param)
                {
                    case 'year-param':
                        return this.yearModel;

                    case 'period-param':
                        if (this.reportOpenData.type == 'Documents.C2.Euol_1_OL')
                            return { Period: { From: `/Date(${ Date.parse(this.ReportDateStartModel) })/`, To: `/Date(${ Date.parse(this.ReportDateEndModel) })/` }, Lang: this.language, Category: this.euolDeclarantCategory };
                        else
                            return { From: `/Date(${ Date.parse(this.ReportDateStartModel) })/`, To: `/Date(${ Date.parse(this.ReportDateEndModel) })/` };

                    case 'date-param':
                        return { Date: `/Date(${ Date.parse(this.ReportDateModel) })/` };

                    default:
                        return null;
                }
            }
            else 
                return null;
        },
        reset() {
            this.ReportDateModel = null;
            this.ReportDateStartModel = null;
            this.ReportDateEndModel = null;
            this.yearModel = null;
            this.language = 'ru-RU';
            this.euolDeclarantCategory = 1;
        }
    }
}
</script>